import { arrayOf, shape, string } from 'prop-types'

import { BaseGridItem as GridItem } from '../base.styles'
import { propTypes as BenefitItemPropTypes } from '../components/BenefitItem'
import Benefits from '../components/BenefitItemList'
import Description from '../components/Description'
import Title from '../components/Title'
import Wrapper from '../components/Wrapper'

const propTypes = {
  description: string,
  items: arrayOf(shape(BenefitItemPropTypes)),
  title: string,
}

const Template03 = ({ description, items, title, ...others }) => {
  return (
    <Wrapper color="neutral.0" {...others}>
      <GridItem gridArea="heading">
        <Title>{title}</Title>
        <Description>{description}</Description>
      </GridItem>
      <GridItem gridArea="items">
        <Benefits items={items} />
      </GridItem>
    </Wrapper>
  )
}

Template03.propTypes = propTypes

export default Template03
