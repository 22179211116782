import { node, string } from 'prop-types'

import { Typography } from '@creditas-ui/typography'

const propTypes = {
  children: node,
  color: string,
}

const Title = ({ children, color }) => {
  if (!children) return null
  const currentColor = color ?? 'neutral.90'
  return (
    <Typography
      color={currentColor}
      variant={{
        _: 'headingSmLight',
        '2xl': 'headingMdLight',
        '5xl': 'headingLgLight',
      }}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  )
}

Title.propTypes = propTypes

export default Title
