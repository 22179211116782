import { styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

import { Grid as UiGrid } from 'components/Grid'

const Grid = styled(UiGrid)`
  padding-top: 42px;
  grid-template-areas:
    '${'benefit-0 '.repeat(4)}'
    '${'benefit-1 '.repeat(4)}'
    '${'benefit-2 '.repeat(4)}'
    '${'benefit-3 '.repeat(4)}';

  ${media.up('2xl')} {
    grid-template-areas:
      '${'benefit-0 '.repeat(4)} ${'benefit-1 '.repeat(4)}'
      '${'benefit-2 '.repeat(4)} ${'benefit-3 '.repeat(4)}';
  }

  ${media.up('5xl')} {
    padding-top: 30px;
    grid-template-areas: '${'benefit-0 '.repeat(3)} ${'benefit-1 '.repeat(
      3,
    )} ${'benefit-2 '.repeat(3)} ${'benefit-3 '.repeat(3)} ';
  }
`

export { Grid }
