import { node, string } from 'prop-types'

import { DescriptionElement } from './styles'

const propTypes = {
  children: node,
  color: string,
}

const Description = ({ children, color }) => {
  if (!children) return null
  const currentColor = color ?? 'neutral.80'
  return (
    <DescriptionElement color={currentColor} variant="bodyMdRegular">
      {children}
    </DescriptionElement>
  )
}

Description.propTypes = propTypes

export default Description
