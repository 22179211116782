import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { Container as CreditasContainer } from 'components/Container'
import { Grid as UiGrid } from 'components/Grid'

const BackgroundWrapper = styled.section(
  ({ color, theme }) => css`
    background-color: ${selectTheme(`colors.${color}`)({ theme })};
  `,
)

const Container = styled(CreditasContainer)`
  padding-top: 64px;
  padding-bottom: 64px;
`

const GridContainer = styled(UiGrid)`
  grid-template-areas:
    '${'heading '.repeat(4)}'
    '${'items '.repeat(4)}';

  ${media.up('2xl')} {
    grid-template-areas:
      '${'heading '.repeat(6)} . .'
      '${'items '.repeat(8)}';
  }

  ${media.up('5xl')} {
    grid-template-areas:
      '${'heading '.repeat(4)} . . . . . . . .'
      '${'items '.repeat(12)}';
  }
`

export { BackgroundWrapper, Container, GridContainer }
