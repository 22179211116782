import { arrayOf, shape, string } from 'prop-types'

import BenefitItem, { propTypes as BenefitItemPropTypes } from '../BenefitItem'

import { Grid } from './styles'

const propTypes = {
  color: string,
  items: arrayOf(shape(BenefitItemPropTypes)),
}

const BenefitItemList = ({ color, items }) => {
  if (!items || !items?.length) return null
  return (
    <Grid as="ul">
      {items.map(({ id, image, title }, index) => (
        <BenefitItem
          key={id}
          index={index}
          title={title}
          image={image}
          color={color}
        />
      ))}
    </Grid>
  )
}

BenefitItemList.propTypes = propTypes

export default BenefitItemList
