import { Grid as UiGrid } from '@creditas-ui/layout'
import { styled } from '@creditas-ui/system'
import { Typography } from '@creditas-ui/typography'
import { media } from '@creditas-ui/utilities'

import { LazyImage } from 'components/LazyImage'

const GridItem = styled(UiGrid)`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  ${media.up('5xl')} {
    margin-bottom: 0;
  }
`

const Title = styled(Typography)`
  padding-left: 10px;
`

const StyledImage = styled(LazyImage)`
  width: 32px;
`

export { GridItem, StyledImage, Title }
