import { node, string } from 'prop-types'

import { BackgroundWrapper, Container, GridContainer } from './styles'

const propTypes = {
  children: node,
  color: string,
}

const Wrapper = ({ children, color, ...others }) => {
  const currentColor = color ?? 'neutral.90'
  return (
    <BackgroundWrapper
      data-testid="section-benefits"
      color={currentColor}
      {...others}
    >
      <Container>
        <GridContainer>{children}</GridContainer>
      </Container>
    </BackgroundWrapper>
  )
}

Wrapper.propTypes = propTypes

export default Wrapper
