import { oneOf } from 'prop-types'

import Template01 from './Template01'
import Template02 from './Template02'
import Template03 from './Template03'

const propTypes = {
  variant: oneOf(['Template01', 'Template02', 'Template03']),
}

const defaultProps = {
  variant: 'Template01',
}

const ELEMENT_VARIANTS = {
  Template01,
  Template02,
  Template03,
}

const Benefits = ({ variant, ...others }) => {
  const currentVariant = variant ?? defaultProps.variant
  const Element = ELEMENT_VARIANTS[currentVariant]
  return <Element {...others} />
}

Benefits.propTypes = propTypes
Benefits.defaultProps = defaultProps

export default Benefits
