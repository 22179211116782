import { styled } from '@creditas-ui/system'
import { Typography } from '@creditas-ui/typography'
import { media } from '@creditas-ui/utilities'

const DescriptionElement = styled(Typography)`
  padding-top: 16px;

  ${media.up('5xl')} {
    margin-bottom: 24px;
  }
`

export { DescriptionElement }
