import { number, object, string } from 'prop-types'

import { GridItem, StyledImage, Title } from './styles'

export const propTypes = {
  color: string,
  image: object,
  index: number,
  title: string,
}

const defaultProps = {
  color: 'neutral.90',
}

const BenefitItem = ({ color, image, index, title }) => {
  const currentColor = color ?? defaultProps.color
  return (
    <GridItem as="li" gridArea={`benefit-${index}`}>
      {image && <StyledImage {...image} />}
      {title && (
        <Title
          color={currentColor}
          variant={{
            _: 'bodySmMedium',
            '5xl': 'bodyMdMedium',
          }}
        >
          {title}
        </Title>
      )}
    </GridItem>
  )
}

BenefitItem.propTypes = propTypes
BenefitItem.defaultProps = defaultProps

export default BenefitItem
