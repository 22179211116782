import { arrayOf, shape, string } from 'prop-types'

import { BaseGridItem as GridItem } from '../base.styles'
import { propTypes as BenefitItemPropTypes } from '../components/BenefitItem'
import Benefits from '../components/BenefitItemList'
import Description from '../components/Description'
import Title from '../components/Title'
import Wrapper from '../components/Wrapper'

const propTypes = {
  description: string,
  items: arrayOf(shape(BenefitItemPropTypes)),
  title: string,
}

const Template01 = ({ description, items, title, ...others }) => {
  const currentColor = 'neutral.0'
  return (
    <Wrapper color="neutral.90" {...others}>
      <GridItem gridArea="heading">
        <Title color={currentColor}>{title}</Title>
        <Description color={currentColor}>{description}</Description>
      </GridItem>
      <GridItem gridArea="items">
        <Benefits color={currentColor} items={items} />
      </GridItem>
    </Wrapper>
  )
}

Template01.propTypes = propTypes

export default Template01
